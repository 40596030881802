@import url('https://fonts.googleapis.com/css2?family=Crete+Round:ital@0;1&family=Inter:wght@400;500;700&family=Poppins&family=Roboto:wght@300;400;700&display=swap');

/* Primary colours */
:root {
  --black: #1c1a19;
  --orange: #ff6b00;
  --off_white: #fff5e1;
  --white: #fff;
  --grey: #3c3a39;
  --light_gray: #dbd8d7;
  --nav_height: 50px;
}

a {
  text-decoration: none;
  /* color: var(--white); */
}

body {
  background: var(--black);
  font-family: 'Roboto', sans-serif;
}

/* [type='button'], */
/* button, */
.button,
[type='submit'] {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  padding: 12px;
  border: none;
  cursor: pointer;
  background: #f55800;
  border: 1px solid #f55800;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* button:hover, */
.button:hover {
  background: #fff5e1;
  border: 1px solid #f55800;
  color: #f55800;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
}

/* button:active, */
.button:active {
  /* Pressed/Clicked */
  background: #e05304;
  color: var(--white);
}

button:disabled {
  color: #5b5957;
  background: #f4f1f0;
}

textarea:focus,
input:focus {
  outline: none;
}

/* End Global Styling */

/* Works Section styling begins here */
.works-section {
  background: var(--white);
  color: var(--black);
  padding: 100px 24px;
  font-family: 'Crete Round', serif;
  font-style: normal;
  font-weight: 400;
}

.works-section h2 {
  font-size: 40px;
  line-height: 52px;
  margin: 50px 0;
}

.works-section hr {
  border: none;
  height: 0.3px;
  background: #212121;
  margin-bottom: 80px;
}

.cards-container {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-content: center;
  gap: 24px;
}

.card {
  color: #091e42;
  font-family: 'Inter', sans-serif;
  display: flex;
  flex-direction: column;
  /* margin-bottom: 24px; */
  align-items: flex-start;

  /* justify-content: space-between; */
}

.card img {
  width: 100%;
}

.card h2 {
  font-size: 32px;
  line-height: 30px;
  margin-top: 50px;
}

.card p {
  font-size: 16px;
  line-height: 24px;
  margin-top: 20px;
}

.card ul,
.project-modal ul {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 12px;
  margin: 20px 0;
}

.card li,
.project-modal li {
  border: 1px solid #8993a4;
  background: var(--white);
  padding: 10px 11.5px;
  /* margin: 20px 3.67% 15px 0; */
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
}

.card-with-bgimage {
  /* background: url("assets/img/Img\ Placeholder.png") no-repeat; */

  /* Background added dynamically from JavaScript */
  background-size: cover;
  color: var(--white);
  min-height: 386px;
}

.card-with-bgimage h2 {
  font-family: 'Crete Round', serif;
  font-weight: 400;
  line-height: 44px;
  margin-top: 84px;
}

.card-with-bgimage p {
  margin: 10px 0;
}

.card-with-bgimage li {
  margin-top: 2px;
  background: rgba(255, 255, 255, 0.24);
  border: none;
}

.card-with-bgimage button {
  margin: 0;
  width: 100%;
}

.project-modal {
  display: none;
  width: 100vw;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1;
  animation: modalopen 1s ease-out;
}

.project-modal .project-margin {
  padding: 76px 16px;
  width: 100%;
  background: transparent;
  overflow-y: scroll;
  height: 100vh;
}

.project-modal .project-modal-container {
  padding: 16px;
  background: white;
  width: 100%;
}

.project-modal .modal-header {
  display: flex;
  justify-content: space-between;
  padding: 0;
}

.project-modal .modal-header h2 {
  padding: 0;
  margin: 0;
  font-size: 32px;
  font-weight: 700;
  color: #172b4d;
  font-family: 'Roboto', sans-serif;
}

.project-modal ul * {
  padding: 2.5%;
  margin: 20px 1.8% 15px 0;
}

.project-modal .close {
  color: #67798e;
  font-size: 35px;
}


.project-modal .close:hover {
  background: #dfe3e8;
  color: white;
  border-radius: 50%;
}

.project-modal .close:active {
  background: #67798e;
  transition-duration: 1s;
  transition-timing-function: ease-out;
}

.project-modal .project-img {
  width: 100%;
}

.project-modal p[class="description"] {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #344563;
}

.project-modal .points-of-action {
  display: flex;
  margin-top: 16px;
  justify-content: space-between;
  font-family: 'Inter', sans-serif;
  font-size: 17px;
  font-weight: 700;
  font-style: normal;
}

.project-modal .points-of-action .icon {
  margin-left: 15px;
}

.project-modal .fa-power-off {
  transform: rotate(45deg);
}

.card-with-bgimage .overlay {
  background: linear-gradient(179.4deg, rgba(38, 38, 38, 0) 0.81%, rgba(38, 38, 38, 0.85) 35.03%);
  z-index: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.card-with-bgimage .overlay .shown {
  padding: 0 16px;
}

/* Works Section styling ends here */

/* About me section begins here */
#about-me {
  padding: 120px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  background: url("assets/img//illustration-about-me-mobile.png") no-repeat right 334px var(--black);
}

#about-me h1 {
  font-family: 'Crete Round', serif;
  color: var(--orange);
  font-weight: 400;
  font-size: 35px;
  line-height: 88px;
}

#about-me p {
  font-size: 16px;
  line-height: 24px;
}

#about-me li {
  font-weight: 400;
  font-size: 18px;
  line-height: 43px;
  text-align: center;
}

#about-me .button {
  align-self: flex-start;
  margin: 24px 0;
}

#about-me hr {
  width: 100%;
  border: 1px solid var(--white);
  margin: 160px 0 135px 0;
}

#about-me h2 {
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  font-style: normal;
  font-family: 'Inter', sans-serif;
}

.title-container {
  display: flex;
  align-items: center;
}

.skill-list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.frameworks,
.technologies {
  margin: 10px 0;
}

.square-icon,
.circle-icon {
  height: 18px;
  width: 18px;
  background: var(--orange);
  margin-right: 12px;
}

.circle-icon {
  border-radius: 50%;
}

.diamond-icon {
  width: 0;
  height: 0;
  border: 12px solid transparent;
  border-bottom-color: var(--orange);
  position: relative;
  top: -12px;
  margin-right: 12px;
}

.diamond-icon::after {
  content: "";
  position: absolute;
  left: -12px;
  top: 12px;
  width: 0;
  height: 0;
  border: 12px solid transparent;
  border-top-color: var(--orange);
}

/* About me section ends here */

/* Contact me section begins here */
#contact {
  background: var(--white);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 24px 0 78px 0;
  font-family: 'Inter', sans-serif;
}

#contact p {
  text-align: center;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  color: #172b4d;
  margin: 0 24px 18px 24px;
}

#contact form {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  color: var(--black);
}

#contact form small {
  padding: 2px 16px;
  display: block;
}

#contact .field {
  margin: 12px;
  width: 90%;
}

#contact input,
#contact textarea {
  border: none;
  border-bottom: 1px solid #dbd8d7;
  width: 100%;
  padding: 14px 16px;
}

#contact textarea {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  height: 114px;
  background: #fbf8f7;
  border: none;
}

#contact textarea::placeholder {
  color: #3c3a39;
  font-family: 'Inter', sans-serif;
}

#contact textarea + small {
  margin-bottom: 10px;
}

#contact input::placeholder {
  color: #979493;
}

#footer-divide {
  border: 1px solid #dfe1e6;
  width: 100%;
}


#contact .success {
  border: 1.5px solid #94c7b0;
}

#contact .error {
  border: 1.5px solid #e67480;
}

#contact .show-error {
  color: red;
}

/* Contact me section ends here */

/* Media Queries Begin here */
@media (min-width: 768px) {
  .works-section {
    padding: 0;
  }

  #works-header {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #091e42;
  }

  #works-header hr {
    margin-bottom: 0;
    flex-grow: 1;
    margin-left: 24px;
    margin-right: 9.79%;
  }

  .cards-container {
    grid-template-columns: repeat(3, 1fr);
    row-gap: 37px;
    column-gap: 24px;
    margin: 0 9.79%;
    padding-bottom: 100px;
    grid-auto-rows: 1fr;
  }

  .card img {
    width: 60%;
  }

  .cards-container :first-child {
    grid-column: 1/-1;
    flex-direction: row;
  }

  .card-with-bgimage h2 {
    margin: 5px 0 0 0;
  }

  #works-header h2 {
    margin-left: 9.79%;
  }

  .card-with-bgimage p {
    margin: 24px 0;
  }

  #leading-card {
    margin-left: 24px;
    width: 45%;
    color: #091e42;
  }

  #leading-card h2 {
    margin: 0;
  }

  #leading-card p {
    font-size: 20px;
    line-height: 28px;
  }

  .card-with-bgimage {
    font-size: 17px;
    line-height: 24px;
    position: relative;
    transform: scale(1);
    transition-duration: 1s;
    /* height: 500px; */
  }

  .card-with-bgimage .shown {
    margin-bottom: -50px;
  }

  .card-with-bgimage .hidden,
  .card-with-bgimage:hover .shown {
    visibility: hidden;
  }

  .card-with-bgimage .overlay {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
  }

  .card-with-bgimage:hover {
    transform: scale(1.02);
    transition-duration: 1s;
  }

  .card-with-bgimage:hover .hidden {
    visibility: initial;
  }

  .card-with-bgimage:hover .overlay {
    background: transparent;
    transform: scale(1.02);
    transition-duration: 0.5s;
    border: 2px solid #091e42;
  }

  .project-modal .project-margin {
    padding: 112px 6.9%;
  }

  .project-modal .project-modal-container {
    display: flex;
    flex-direction: column;
    padding: 24px;
  }

  .project-modal ul * {
    padding: 1%;
    margin: 20px 0.8% 15px 0;
  }

  .project-modal .project-modal-body {
    display: flex;
  }

  .project-modal .project-img {
    width: auto;
    max-width: 60%;
    max-height: 600px;
  }

  .project-modal .project-describe {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 3%;
  }

  .project-modal p[class="description"] {
    line-height: 30px;
    font-weight: 400;
  }

  .project-modal .points-of-action {
    justify-content: flex-start;
  }

  .project-modal .points-of-action :first-child {
    margin-right: 7px;
  }

  /* About me */
  #about-me {
    padding: 130px 8%;
    align-items: flex-start;
    background: url("assets/img/illustration-about\ me@2x.png") no-repeat right 120px var(--black);
    background-size: 35%;
    width: 100%;
  }

  #about-me h1 {
    color: #f55800;
    font-size: 45px;
    line-height: 82px;
  }

  #about-me p {
    font-size: 20px;
    line-height: 32px;
    width: 50%;
  }

  #about-me li {
    font-weight: 400;
    font-size: 18px;
    line-height: 43px;
    text-align: center;
  }

  #about-me hr {
    width: 100%;
    border: 1px solid var(--white);
    margin: 160px 0 135px 0;
  }

  .skill-list {
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    width: 100%;
  }

  .skill-list ul {
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .skill-list .languages,
  .skill-list .frameworks,
  .skill-list .technologies {
    margin: 0;
    margin-right: 7%;
  }

  .square-icon,
  .circle-icon {
    background: #f55800;
  }

  .diamond-icon {
    border-bottom-color: #f55800;
  }

  .diamond-icon::after {
    border-top-color: #f55800;
  }

  #contact {
    align-items: flex-start;
    flex-direction: row;
    padding: 130px 0 124px 0;
    flex-wrap: wrap;
  }

  #contact p {
    text-align: left;
    font-size: 40px;
    line-height: 52px;
    width: 43%;
    color: #262626;
    margin: 0;
    padding: 0 24px 0 135px;
  }

  #contact form {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    color: var(--black);
    width: 57%;
  }

  #contact input,
  #contact textarea {
    width: calc(100% - 15%);
    padding: 14px 15% 14px 14px;
    margin: 0 15px 0 15px;
  }

  #contact button {
    margin: 0 15px 15px 29px;
  }

  #contact textarea {
    font-family: 'Roboto', sans-serif;
  }

  #contact .socials .icon {
    margin: 15px 10px 15px 10px;
  }

  #footer-bottom {
    display: none;
  }

  @media (max-width: 900px) {
    .cards-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  /* 768px < width < 1000px */
  @media (max-width: 1000px) {
    .card h2 {
      font-size: 28px;
    }

    #leading-card p {
      font-size: 16px;
      line-height: 25px;
    }
  }
}

/* Media Queries End here */

/* Animation keyframes Begin here */

@keyframes bounceIn {
  0% { transform: scale(1, 1)    translateX(0); }
  10% { transform: scale(1.1, 0.9) translateX(0); }
  30% { transform: scale(0.9, 1.1) translateX(-100px); }
  50% { transform: scale(1, 1)    translateX(0); }
  57% { transform: scale(1, 1)    translateX(-7px); }
  64% { transform: scale(1, 1)    translateX(0); }
  100% { transform: scale(1, 1)    translateX(0); }
}

@keyframes modalopen {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes modalclose {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(-100vh);
  }
  
  100% {
    transform: translateY(0);
  }
}

@keyframes slideOut {
  0% {
    transform: translateY(0);
  }
  
  100% {
    transform: translateY(-100vh);
  }
}

/* Animation keyframes End here */
